@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Red+Hat+Text:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  display: flex;

  width: 200px;
  height: 50px;
  padding: 10px;
  font-size: 0.8em;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: black;
  color: white;
  cursor: pointer;
  margin-top: 30%;
}

@media (max-width: 520px) {
  .button {
    margin-top: 40% !important;
  }
}
